define("discourse/plugins/discourse-authentication-validations/discourse/connectors/create-account-after-user-fields/validations", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "discourse/plugins/discourse-authentication-validations/discourse/helpers/setup-user-field-validation", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _setupUserFieldValidation, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Validations extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "userFieldValidations", [_service.service]))();
    #userFieldValidations = (() => (dt7948.i(this, "userFieldValidations"), void 0))();
    constructor() {
      super(...arguments);
      this.userFieldValidations.totalCustomValidationFields = this.args.outletArgs.userFields.filterBy("field.hasCustomValidation").length;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#each @outletArgs.userFields as |field|}}
          {{#if field.field.has_custom_validation}}
            {{setupUserFieldValidation (hash field=field.field value=field.value)}}
          {{/if}}
        {{/each}}
      
    */
    {
      "id": "pJV56UKi",
      "block": "[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"userFields\"]]],null]],null],null,[[[41,[30,2,[\"field\",\"has_custom_validation\"]],[[[1,\"        \"],[1,[28,[32,0],[[28,[32,1],null,[[\"field\",\"value\"],[[30,2,[\"field\"]],[30,2,[\"value\"]]]]]],null]],[1,\"\\n\"]],[]],null]],[2]],null],[1,\"  \"]],[\"@outletArgs\",\"field\"],false,[\"each\",\"-track-array\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-authentication-validations/discourse/connectors/create-account-after-user-fields/validations.js",
      "scope": () => [_setupUserFieldValidation.default, _helper.hash],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Validations;
});