define("discourse/plugins/discourse-authentication-validations/discourse/services/user-field-validations", ["exports", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service"], function (_exports, _tracking, _object, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserFieldValidations extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "totalCustomValidationFields", [_tracking.tracked], function () {
      return 0;
    }))();
    #totalCustomValidationFields = (() => (dt7948.i(this, "totalCustomValidationFields"), void 0))();
    currentCustomValidationFieldCount = 0;
    setValidation(userField, value) {
      this._bumpTotalCustomValidationFields();
      if (this.currentCustomValidationFieldCount === this.totalCustomValidationFields) {
        (0, _runloop.next)(() => {
          this.crossCheckValidations(userField, value);
          this.hideNestedCustomValidations(userField, value);
        });
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "setValidation", [_object.action]))();
    hideNestedCustomValidations(userField, value) {
      if (!this._shouldShow(userField, value)) {
        const nestedUserFields = this.site.user_fields.filter(field => userField.target_user_field_ids.includes(field.id)).flatMap(nestedField => this.site.user_fields.filter(field => nestedField.target_user_field_ids.includes(field.id)));

        // Clear and hide nested fields
        nestedUserFields.forEach(field => this._clearUserField(field));
        this._updateTargets(nestedUserFields.map(field => field.id), false);
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "hideNestedCustomValidations", [_object.action]))();
    crossCheckValidations(userField, value) {
      this._updateTargets(userField.target_user_field_ids, this._shouldShow(userField, value));
    }
    static #_5 = (() => dt7948.n(this.prototype, "crossCheckValidations", [_object.action]))();
    _updateTargets(userFieldIds, shouldShow) {
      userFieldIds.forEach(id => {
        const userField = this.site.user_fields.find(field => field.id === id);
        const className = `user-field-${userField.name.toLowerCase().replace(/\s+/g, "-")}`;
        const userFieldElement = document.querySelector(`.${className}`);
        if (userFieldElement && !shouldShow) {
          // Clear and hide nested fields
          userFieldElement.style.display = "none";
          this._clearUserField(userField);
        } else {
          userFieldElement.style.display = "";
        }
      });
    }
    _shouldShow(userField, value) {
      let stringValue = value?.toString(); // Account for checkbox boolean values and `null`
      let shouldShow = userField.show_values.includes(stringValue);
      if (value === null && userField.show_values.includes("null")) {
        shouldShow = true;
      }
      return shouldShow;
    }
    _clearUserField(userField) {
      switch (userField.field_type) {
        case "confirm":
          userField.element.checked = false;
          break;
        case "dropdown":
          userField.element.selectedIndex = 0;
          break;
        default:
          userField.element.value = "";
          break;
      }
    }
    _bumpTotalCustomValidationFields() {
      if (this.totalCustomValidationFields !== this.currentCustomValidationFieldCount) {
        this.currentCustomValidationFieldCount += 1;
      }
    }
  }
  _exports.default = UserFieldValidations;
});